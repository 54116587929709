<template>
  <div class="card h-100">
    <div class="p-2 h4 font-bolder text-danger">
      รายการถอน
    </div>
    <b-table
      v-if="data && data.todayWit"
      responsive
      :items="data.todayWit.slice(0, 10)"
      :fields="fields"
      show-empty
    >
      <template #empty="scope">
        <div class="p-2 text-center">
          <img
            src="/box.png"
            alt="empty"
            height="40px"
          > {{ scope.emptyFiltered ? 'No matching records found' :
            'ไม่พบข้อมูล' }}
        </div>
      </template>
      <!-- <template #cell(acc_name)="data">
            <div class="text-center">
              {{ data.value.fullname }}
            </div>
          </template> -->
      <template #cell(bank)="data">
        <div class="text-center">
          <img
            :src="`/bankIcon/${data.item.bank_path_photo}`"
            alt=""
            height="25"
            width="25"
            class="rounded-circle"
          >
          <div>
            <span class="mb-0">
              {{ data.value.acc_no }}
            </span>
          </div>
        </div>
      </template>

      <template #cell(status)="data">
        <div
          v-if="data.item.status === 'success' || data.item.status === 'manual_success'"
          class="d-flex align-items-center"
        >
          <div class="popout active_s mr-75" />
          <span>อนุมัติ</span>
        </div>
      </template>
      <template #cell(amount)="data">
        {{ Commas(data.item.amount.toFixed(2)) }}
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BRow,
  BIcon,
  BCol,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BCardText,
} from 'bootstrap-vue'
import moment from 'moment-timezone'

export default {
  components: {
    BRow,
    BIcon,
    BCol,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BCardText,
  },
  props: ['data'],
  data() {
    return {
      fields: [
        { key: 'cus', label: 'ชื่อ' },
        { key: 'status', label: 'สถานะ' },
        { key: 'bank', label: 'ธนาคาร' },
        {
          key: 'created_at',
          label: 'สั่งถอนเมื่อ',
          formatter: value => (value
            ? moment(value)
              .tz('Asia/Bangkok')
              .format('DD-MM-YYYY HH:mm')
            : ''),
        },
        { key: 'amount', label: 'ยอด' },
      ],
      items: [],
    }
  },
  computed: {
    transactionData() {
      return [
        {
          icon: 'TrendingUpIcon',
          title: 'ฝากเงิน',
          en: 'Deposit',
          amount: this.$props.data && this.$props.data.depSum.length ? this.$props.data.depSum[0].amount : 0,
          avatarVariant: 'light-success',
        },
        {
          icon: 'TrendingDownIcon',
          title: 'ถอนเงิน',
          en: 'Withdraw',
          amount: this.$props.data && this.$props.data.witSum.length ? this.$props.data.witSum[0].amount : 0,
          avatarVariant: 'light-danger',
        },
        {
          icon: 'DollarSignIcon',
          title: 'โบนัส',
          en: 'Bonus',
          amount: this.$props.data && this.$props.data.bonusSum.length ? this.$props.data.bonusSum[0].amount : 0,
          avatarVariant: 'light-info',
        },
        {
          icon: 'PocketIcon',
          title: 'เกม',
          en: 'Game',
          amount: '0',
          avatarVariant: 'light-warning',
        },
      ]
    },
  },
  methods: {

    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
.card {
  border-radius: 1rem;
}
</style>
